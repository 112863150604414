import React, { useCallback } from 'react'
import styles from './resources.module.scss'
import ComImage from '@components/ComImage'
import { defaultList } from '@utils/constant'
const Resources = () => {

  const goDetail = useCallback((url) => {
    if (url) {
      window.open(url)
    }
  }, [])
  return (
    <div className={styles.resourcesCon} >
      <div className={styles.title}>
        Resources for getting started
      </div>
      <div className={styles.contentBox}>
        {defaultList.map( (item, index) => {
          return (
            <div className={styles.recItem} key={index} onClick={() => goDetail(item?.url)}>
              <div className={styles.recImgCon}>
                <ComImage src={item.imgUrl} width={440} height={285} alt="img" className={styles.recImg} />
              </div>
              <div className={styles.recTextCon}>
                <div className={styles.recTextTitle}>
                  {item?.title}
                </div>
                <div className={styles.recTextDesc}>
                  an exclusive metaverse community with the power to build it from the
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Resources
