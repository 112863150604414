import { metrics } from '@/api'
import isMobile from 'is-mobile'
import { isProduction } from '@utils/utils'

export const clickExp = (data) => {
  if (!isProduction()) return
  const type = 'click'
  const address = localStorage.getItem('address')
  const mobile = isMobile()
  const time = new Date().getTime()
  const href = window.location.href
  const search = window.location.search
  const searchParams = new URLSearchParams(search)
  const source = searchParams.get('source')
  const userAgent = navigator.userAgent
  const uuid = getUuid()
  const p = { type, uuid, source, address, mobile, time, href, search, userAgent, ...data }
  metrics({ content: JSON.stringify(p) })
}

export const exp = (data) => {
  if (!isProduction()) return

  const type = 'pageExp'
  const address = localStorage.getItem('address')
  const mobile = isMobile()
  const time = new Date().getTime()
  const href = window.location.href
  const search = window.location.search
  const searchParams = new URLSearchParams(search)
  const source = searchParams.get('source')
  const userAgent = navigator.userAgent
  const uuid = getUuid()
  const p = { type, source, uuid, address, mobile, time, href, search, userAgent, ...data }
  metrics({ content: JSON.stringify(p) })
}

export const getUuid = () => {
  let uuid = localStorage.getItem('uuid')
  if (!uuid) {
    uuid = guid()
    localStorage.setItem('uuid', uuid)
  }
  return uuid
}

const guid = () => {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)

  }
  return (`${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`)
}