import React from 'react'

import styles from './supported.module.scss'

import './supported.module.scss'

export default function Supported() {
  const goDetail = (url) => {
    if (url) {
      window.open(url)
    }
  }
  const data = [
    {
      bgSrc: 'https://s3-nswap-base.nswap.com/0.3.4/nftstar@3x.png',
      link: 'https://twitter.com/THENFTSTAR',
    },
    {
      bgSrc: 'https://s3-nswap-base.nswap.com/0.3.4/nftgo@3x.png',
      link: 'https://twitter.com/nftgoio',
    },
    {
      bgSrc: 'https://s3-nswap-base.nswap.com/0.3.4/binance@3x.png',
      link: 'https://twitter.com/binance',
    },
    {
      bgSrc: 'https://s3-nswap-base.nswap.com/0.3.4/ipollo@3x.png',
      link: 'https://twitter.com/iPolloverse',
    },
    {
      bgSrc: 'https://s3-nswap-base.nswap.com/0.3.4/nd_labs@3x.png',
      link: 'https://twitter.com/imNDLABS',
    },
    {
      bgSrc: 'https://s3-nswap-base.nswap.com/0.3.4/metaera@3x.png',
      link: 'https://twitter.com/MetaEra_Media',
    },
    {
      bgSrc: 'https://s3-nswap-base.nswap.com/0.3.4/drji@3x.png',
      link: 'https://twitter.com/drjiclub',
    },
    {
      bgSrc: 'https://s3-nswap-base.nswap.com/0.3.4/ther@3x.png',
      link: 'https://twitter.com/Theirsverse',
    },
    {
      bgSrc: 'https://s3-nswap-base.nswap.com/0.3.4/cross@3x.png',
      link: 'https://twitter.com/CSpaceOfficial',
    },
    {
      bgSrc: 'https://s3-nswap-base.nswap.com/0.3.4/element@3x.png',
      link: 'https://twitter.com/Element_Market',
    },
    {
      bgSrc: 'https://s3-nswap-base.nswap.com/0.3.4/opensea.png',
      link: 'https://twitter.com/opensea',
    },
    {
      bgSrc: 'https://s3-nswap-base.nswap.com/0.3.4/debox@3x.png',
      link: 'https://twitter.com/DeBox_Social',
    },
    // {
    //   bgSrc: 'https://s3-nswap-base.nswap.com/0.3.4/beast.png',
    //   link: 'https://twitter.com/akcbcn',
    // },
    {
      bgSrc: 'https://s3-nswap-base.nswap.com/0.3.4/rug_radio@3x.png',
      link: 'https://twitter.com/RugRadioCN',
    },
  ]
  return (
    <div className={styles.supCon}>
      <div className={styles.title}>
        Supported by
      </div>
      <div className={styles.supImgCon}>
        {data.map((item, index) => {
          return (
            <div className={styles.bgBox} key={index} onClick={() => goDetail(item.link)}>
              <img src={item.bgSrc} width={160} height={40} alt="banner" />
            </div>
          )
        })}
      </div>
    </div>
  )
}
