
import { get } from '@/utils/axios'

//global代表全网，local代表本地，因为没有全网数据
export const getRankingsETH = params => get('/api/v2/rankings/global', params)

export const getRankingsETHW = params => get('/api/multi-chain/10001/rankings/local', params)

export const getRankingsBSC = params => get('/api/multi-chain/56/rankings/global', params)

export const getRankingsIPO = params => get('/api/multi-chain/101/rankings/top', params)

export const getRankingsTop = params => get('/api/v2/rankings/top', params)

export const getRankingsTrending = params => get('/api/v2/rankings/trending', params)