import React, { useState, useEffect, useCallback } from 'react'
import Router from 'next/router'
import ComImage from '@components/ComImage'
import { getFeaturedList } from '@/api'
import { errorCaptured } from '@contract/utils'
import { UP_DC_HOVER, UP_TWITTER_NORMAL, UP_TWITTER_HOVER, UP_DC_NORMAL } from '@utils/img'

import styles from './upcoming.module.scss'

const Upcoming = () => {
  const [list, setList] = useState([])

  // const goProjectDetail = useCallback((item) => {
  //   if (item?.project_id) {
  //     Router.push(`/launch/${item?.project_id}`)
  //   }
  // }, [])

  const goDetail = (e, url) => {
    e.stopPropagation()
    if (url) {
      window.open(url)
    }
  }

  const getList = async() => {
    const [err, res] = await errorCaptured(getFeaturedList, { page: 1, page_size: 6 })
    if (err) {
      console.log(console.log(err, 'error getFeaturedList'))
    }
    if (res) {
      const { data } = res || {}
      const { result } = data || {}
      setList(result)
    }
  }
  useEffect(() => {
    getList()
  }, [])

  return (
    <div className={styles.upComingCon}>
      <div className={styles.title}>
        Upcoming
      </div>
      <div className={styles.contentBox}>
        {list.map( (item, index) => {
          return (
            <div className={styles.recItem} key={index}>
              <div className={styles.recImgCon}>
                <ComImage src={item.meta_file_uri} width={285} height={285} alt="img" className={styles.recImg} />
                <div className={styles.maskBox} >
                  <div className={styles.iconBox}>

                    {item?.twitter && (
                      <div className={styles.hoverIcon} onClick={e => goDetail(e, item?.twitter)}>
                        <img src={UP_TWITTER_HOVER} className={styles.hoverImg} />
                        <img src={UP_TWITTER_NORMAL} className={styles.normalImg}/>
                      </div>
                    )}
                    {item?.discord && (
                      <div className={styles.hoverIcon} onClick={e => goDetail(e, item?.discord)}>
                        <img src={UP_DC_HOVER} className={styles.hoverImg}/>
                        <img src={UP_DC_NORMAL} className={styles.normalImg}/>
                      </div>
                    )}
                  </div>

                </div>
              </div>
              <div className={styles.recTextCon}>
                <div className={styles.recTextTitle}>
                  {item?.name}
                </div>
                <div className={styles.recTextDesc}>
                  {item?.description}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Upcoming
