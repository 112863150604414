import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './homeKol.module.scss'
import { KOL_DATA } from '@utils/constant'
import { imgOnError } from '@/utils/utils'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

import './homeKol.module.scss'
import { usePhone } from '@utils/utils'

// import required modules
import { Pagination } from 'swiper'

export default function HomeKol() {
  const isPhone = usePhone()

  const goDetail = (item) => {
    const { link } = item
    if (link) {
      window.open(link)
    }
  }

  if (isPhone) {
    return (
      <div className={styles.kolCon}>
        <div className={styles.title}>
          KOL
        </div>
        <div className={styles.kolContentBox}>

          {KOL_DATA.map((item) => {
            return (
              <div key={item.bgSrc}>
                <div className={styles.BannerItemCon} onClick={() => goDetail(item)}>
                  <img src={item.avatar} width={180} height={280} alt="banner" className={styles.bgImg} onError={imgOnError} />
                  <div className={styles.descCon}>
                    <div className={styles.descTitle}>
                      {item?.name}
                    </div>
                    <div className={styles.content}>
                      {item?.desc}
                    </div>
                  </div>

                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.kolCon}>
      <div className={styles.title}>
        KOL
      </div>
      <Swiper
        slidesPerView={9}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className={styles.mySwiper}
      >
        {KOL_DATA.map((item) => {
          return (
            <SwiperSlide key={item.bgSrc}>
              <div className={styles.BannerItemCon} onClick={() => goDetail(item)}>
                <img src={item.avatar} width={180} height={280} alt="banner" className={styles.bgImg} onError={imgOnError} />
                <div className={styles.descCon}>
                  <div className={styles.descTitle}>
                    {item?.name}
                  </div>
                  <div className={styles.content}>
                    {item?.desc}
                  </div>
                </div>

              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}
