import React from 'react'
import { HOME_BANNER_ARROW2 } from '@utils/img'
import ComImage from '@components/ComImage'
import styles from './homeBannerItem.module.scss'
const HomeBannerItem = ({ bannerItem }) => {

  const { web_banner, BannerKolList, url, title, description } = bannerItem

  const goDetail = () => {
    if (url) {
      window.open(url)
    }
  }

  const goKol = (item) => {
    const { media_url } = item
    if (media_url) {
      window.open(media_url)
    }
  }
  return (
    <div className={styles.BannerItemCon}>
      <ComImage src={web_banner} width={1792} height={560} alt="banner" layout="fill" objectFit="cover" className={styles.bgImg}/>
      <div className={styles.descCon}>
        <div className={styles.title}>
          {title}
        </div>
        <div className={styles.content}>
          {description}
        </div>
        <div className={styles.avatarImgBox}>
          {BannerKolList.map((item) => {
            return (
              <div className={styles.imgItemBox} key={item.banner_id} onClick={() => goKol(item)}>
                <ComImage src={item?.image_url} width={36} height={36} alt="kolImg" layout="fill" objectFit="cover" className={styles.imgItem} />
              </div>
            )
          })}
        </div>
        <div className={styles.more} onClick={goDetail}>
          <div className={styles.moreContent}>
            <div className={styles.text}>More Detail</div>
            <div className={styles.rightArrow}>
              <img src={HOME_BANNER_ARROW2} />
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeBannerItem