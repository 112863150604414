import React, { useEffect, useCallback, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { getBannerList } from '@/api/index'
import { errorCaptured } from '@contract/utils'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
// import 'swiper/swiper-bundle.css'
import styles from './homeBannerView.module.scss'
import HomeBannerItem from './homeBannerItem/homeBannerItem'
import ThumbItem from './thumbItem/thumbItem'
import { FreeMode, Navigation, Thumbs, EffectFade, Pagination } from 'swiper'
import {
  useMediaQuery,
} from '@mui/material'
import isMobile from 'is-mobile'

import { usePhone } from '@utils/utils'

// 原来的hot接口
export default function HomeBannerView () {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [banner, setBanner] = useState([])

  // const isPhone = usePhone()
  const mobile = isMobile()
  console.log(mobile, 'mobile----')

  const handleSlideChange = (swiper) => {
    console.log(swiper.activeIndex, 'swiper.activeIndex---')
    setActiveIndex(swiper.activeIndex)
  }

  const matches = useMediaQuery('(max-width:1600px)')

  const newData = useCallback(async () => {
    const [err, res] = await errorCaptured(getBannerList)
    if (err) {
      console.log(err, 'getBanner error')
      setBanner([])
      return
    }
    if (res) {
      if (res?.code === 200) {
        setBanner(res?.data || [])
      }
    }
  }, [])

  const pagination = {
    type: 'custom',
    clickable: true,
    renderCustom(swiper, current, total) {
      console.log(swiper, current, total, 'total--')
      return (
        `<div class=${styles.indicatorCon}>
          <div class=${styles.indicator_first}></div>
          <div class=${styles.contentBg}>
          <div class=${styles.contentProgress} style="transform: translate(${(current / total) * 4 }rem) "
          ></div>
          </div>
          <div class=${styles.indicator_end}></div>
        </div>`
      )
    },
  }

  useEffect(() => {
    newData()
  }, [])

  if (mobile) {
    return (
      <div>
        <Swiper
          loop={true}
          pagination={pagination}
          spaceBetween={10}
          modules={[Pagination]} // 引入模块
          className={styles.mySwiper2}
        >
          {banner.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <HomeBannerItem bannerItem = {item} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    )
  }

  return (
    <div className={styles.bannerCon}>
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        spaceBetween={10}
        navigation={false} // 去掉导航条
        thumbs={{ swiper: thumbsSwiper }} // 设置缩略图
        modules={[FreeMode, Thumbs, EffectFade]} // 引入模块
        className={styles.mySwiper2}
        effect="fade"
        onSlideChange={handleSlideChange}

      >
        {banner.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <HomeBannerItem bannerItem = {item} />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className={styles.thumbsCon}>
        <Swiper
          // {...swiperOptions.thumbs}
          onSwiper={setThumbsSwiper} // 缩略图关联
          spaceBetween={16}
          slidesPerView={matches ? 6 : 7}
          freeMode={true}
          watchSlidesProgress={true}
          watchSlidesVisibility={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className={styles.mySwiperThumbs}
        >
          {banner.map((item, index) => {
            return (
              <SwiperSlide key={item.id}>
                <ThumbItem
                  bgSrc={item?.web_banner}
                  activeIndex={activeIndex}
                  title={item?.title}
                  virtualIndex={index}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>

    </div>
  )
}

