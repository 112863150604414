import React, { useCallback, useMemo } from 'react'
import Router from 'next/router'
import useMediaQuery from '@mui/material/useMediaQuery'
import classnames from 'classnames'
import { numFormatter } from '@utils/utils'
import { checkCollectionImported, checkCollectionImportedBSC } from '@/api'
import { showSnackBar } from '@/components/CustomizedSnackbars'
import { imgOnError } from '@/utils/utils'
import Loading from '@components/Loading'
import { getChainCurrencyName } from '@utils/networks.js'
import {
  NewVerifiedIcon,
} from '@components/Icons'
import { usePhone } from '@utils/utils'

import styles from './RankContent.module.scss'

//链不同，表结构不一样
const getColumns = (chain) => {
  if (chain === 1) {
    return {
      collection: {
        width: '30%',
        isNoRequire: true,
        label: 'COLLECTION',
        render(index, record) {
          const { oss_uri, name, auth } = record
          return (
            <>
              <span className={styles.sort}>{record.initialIndex + 1}</span>
              <img src={oss_uri} onError={imgOnError}/>
              <div className={styles.name}>
                <span className={styles.collection_name}>{name}</span>
                <div className={styles.vip}>
                  {auth === 1 && (
                    <NewVerifiedIcon width={16} height={16} />
                  )}
                </div>
              </div>
            </>
          )
        },
      },
      volume: {
        key: 'volume',
        label: 'VOLUME',
        width: '20%',
        render(_, record) {
          const { volume, volume_change } = record || {}
          const num = numFormatter(volume)
          return (
            <div>
              <div className={styles.number}>{num === '0' ? '-' : `${num} ${getChainCurrencyName(chain)}`}</div>
              <div className={classnames(styles.change, volume_change > 0 ? styles.increase : styles.down ) }>
                {`${volume_change < 0 ? '' : '+'}${numFormatter(volume_change * 100)}%`}
              </div>
            </div>
          )
        },
      },
      'Sales': {
        key: 'sales',
        label: 'SALES',
        width: '15%',
        render(_, record) {
          const { sales } = record
          const num = numFormatter(sales)
          return (
            <>
              <span className={styles.number}>{num === 0 ? '-' : num}</span>
            </>
          )
        },
      },
      'floor price': {
        key: 'floor_price',
        label: 'FLOOR PRICE',
        width: '20%',
        render(_, record) {
          const { floor_price, floor_price_change } = record
          const num = numFormatter(floor_price)

          return (
            <div>
              <span className={styles.number}>{num === '0' ? '-' : `${num} ${getChainCurrencyName(chain)}`}</span>
              <div className={classnames(styles.change, floor_price_change > 0 ? styles.increase : styles.down ) }>
                {`${floor_price_change < 0 ? '' : '+'}${numFormatter(floor_price_change * 100)}%`}
              </div>
            </div>
          )
        },
      },
      // 'listed': {
      //   key: 'listed',
      //   label: 'LISTED',
      //   width: '20%',
      //   render(_, record) {
      //     const { listed } = record
      //     const num = numFormatter(listed)
      //     return (
      //       <>
      //         <span className={styles.number}>{num === '0' ? '-' : num}</span>
      //       </>
      //     )
      //   },
      // },
      'items': {
        width: 175,
        label: 'ITEMS',
        width: '20%',
        render(_, record) {
          const { item_num } = record
          const num = numFormatter(item_num)
          return (
            <>
              <span className={styles.number}>{num === '0' ? '-' : num}</span>
            </>
          )
        },
      },
    }
  }
  return {
    collection: {
      width: '25%',
      isNoRequire: true,
      label: 'COLLECTION',
      render(index, record) {
        const { oss_uri, name } = record
        return (
          <>
            <span className={styles.sort}>{record.initialIndex + 1}</span>
            <img src={oss_uri} onError={imgOnError}/>
            <div className={styles.name}>
              <span className={styles.collection_name}>{name}</span>
            </div>
          </>
        )
      },
    },
    volume: {
      key: 'volume',
      label: 'VOLUME',
      width: '20%',
      render(_, record) {
        const { volume } = record || {}
        const num = numFormatter(volume )
        return (
          <div>
            <div className={styles.number}>{num === '0' ? '-' : `${num} ${getChainCurrencyName(chain)}`}</div>
          </div>
        )
      },
    },
    '% change': {
      key: 'change',
      label: '% change',
      width: '20%',
      render(_, record) {
        const { change } = record || {}

        return !['-'].includes(change)
          ? (
            <span className={classnames(change < 0 ? styles.down : styles.increase, styles.number)}>
              {`${change < 0 ? '' : '+'}${numFormatter(change * 100)}%`}
            </span>
          )
          : '-'
      },
    },
    'floor price': {
      key: 'floor_price',
      label: 'FLOOR PRICE',
      width: '20%',
      render(_, record) {
        const { floor_price } = record
        const num = numFormatter(floor_price)

        return (
          <div>
            <span className={styles.number}>{num === '0' ? '-' : `${num} ${getChainCurrencyName(chain)}`}</span>
          </div>
        )
      },
    },
    'items': {
      width: 175,
      label: 'ITEMS',
      width: '20%',
      render(_, record) {
        const { item_num } = record
        const num = numFormatter(item_num)
        return (
          <>
            <span className={styles.number}>{num === '0' ? '-' : num}</span>
          </>
        )
      },
    },
  }
}

const RankContent = (props) => {
  const {
    chain,
    loading,
    sortInfo,
    dataSource,
  } = props

  const isPC = useMediaQuery('(min-width: 1200px)')
  const isPhone = usePhone()

  const handleRedirect = useCallback(async (address) => {
    const chainName = getChainCurrencyName(chain).toLocaleLowerCase()
    if (chainName === 'eth') {
      const res = await checkCollectionImported({ contract_address: address })
      if (res && res.data) {
        Router.push(`/collection/${chainName}/${address}`)
        return
      }
      showSnackBar({ type: 'error', message: 'No record yet' })
      return
    }

    if (chainName === 'bsc') {
      const res = await checkCollectionImportedBSC({ contract_address: address })
      if (res && res.data) {
        Router.push(`/collection/${chainName}/${address}`)
        return
      }
      showSnackBar({ type: 'error', message: 'No record yet' })
      return
    }

    Router.push(`/collection/${chainName}/${address}`)
  }, [chain])

  const columns = useMemo(() => {
    return getColumns(chain)
  }, [chain])

  const RankingHeader = () => {
    const { sort_by, sort_direction } = sortInfo
    return (
      <>
        <div className={styles.list_header}>
          {Object.keys(columns).map((key) => {
            const columnItem = columns[key]
            const { width, isNoRequire, key: columnKey, label } = columnItem

            const isChecked = !!sort_by && sort_by === columnKey
            const noClickColumn = !columnKey

            return (
              <div
                key={key}
                className={classnames(styles.item)}
                style={{ width: width, justifyContent: isNoRequire ? 'flex-start' : 'flex-end' }}
              >
                <div
                  className={classnames(styles.title, noClickColumn ? styles.no_click : '' )}
                >
                  <span>{label}</span>
                </div>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  const RankingTable = () => {
    return (
      <div className={styles.list_content}>
        {Array.isArray(dataSource) ? dataSource.map((item, index) => {
          return (
            <div key={item.address} className={styles.list_detail} onClick={() => handleRedirect(item.address)}>
              {Object.keys(columns).map((key) => {
                const _column = columns[key]
                const { width, isNoRequire } = _column
                return (
                  <div
                    key={key}
                    className={styles.item}
                    style={{ width: width, justifyContent: isNoRequire ? 'flex-start' : 'flex-end' }}
                  >
                    {_column.render && _column.render(index, item)}
                  </div>
                )
              })}
            </div>
          )
        }) : null}
      </div>
    )
  }

  // mobile
  const RankingMobileHeader = () => {
    return (
      <div className={styles.list_mobile_header}>
        <span>COLLECTION</span>
        <span>VOLUME</span>
      </div>
    )
  }

  const RankingMobileTable = () => {
    const renderVolume = (record) => {
      const { volume } = record || {}

      const num = numFormatter(volume)
      return num === '0' ? '-' : num
    }

    const renderRatio = (record) => {
      const { change } = record || {}

      return !['-'].includes(change)
        ? (
          <span className={classnames(change < 0 ? styles.down : styles.increase, styles.text)}>
            {`${change < 0 ? '' : '+'}${numFormatter(change * 100)}%`}
          </span>
        )
        : '-'
    }

    return (
      <div className={styles.list_mobile_content} >
        {Array.isArray(dataSource) ? dataSource.map((item, index) => {
          const { oss_uri, name, floor_price, address, auth } = item || {}
          const _floor_price = numFormatter(floor_price)
          return (
            <div key={address} className={styles.list_mobile_item} onClick={() => handleRedirect(item.address)}>
              <div className={styles.info}>
                <span className={styles.sort}>{index + 1}</span>
                <img src={oss_uri} onError={imgOnError} />
                <div className={styles.name_price}>
                  <div className={styles.name}>
                    <span className={classnames(styles.collection_name)}>{name}</span>
                    <div className={styles.vip}>
                      {auth === 1 && (
                        <NewVerifiedIcon width={16} height={16} />
                      )}
                    </div>
                  </div>

                  {/* <div className={styles.floor_price}>
                    <span className={styles.text}>Floor price:</span>
                    <span className={classnames(styles.price, styles.text)}>
                      {_floor_price === '0' ? '-' : `${_floor_price} ${getChainCurrencyName(chain)}` }
                    </span>
                  </div> */}
                </div>
              </div>
              <div className={styles.volume} >
                <div className={styles.volume_detail}>
                  <span className={styles.text}>{renderVolume(item)}</span>
                  <span className={styles.text}>{getChainCurrencyName(chain)}</span>
                </div>
                {/* {renderRatio(item)} */}
              </div>
            </div>
          ) }) : null}
      </div>
    )

  }

  return (
    <div className={styles.list_container}>
      {isPC ? (
        <>
          <RankingHeader />
          {loading
            ? (
              <div className={styles.loading_wrap}>
                <Loading
                  boxStyle={{ width: '24px', height: '24px' }} circleStyle={{ background: '#ddd' }}
                />
              </div>
            ) : <RankingTable />}
        </>
      ) : (
        <>
          <RankingMobileHeader />
          {
            loading ? (
              <div className={styles.loading_wrap}>
                <Loading
                  boxStyle={{ width: '24px', height: '24px' }} circleStyle={{ background: '#ddd' }}
                />
              </div>
            ) : <RankingMobileTable />
          }
        </>
      ) }
    </div>
  )
}

export default RankContent