import { useEffect, useState, useMemo, useRef } from 'react'
import classnames from 'classnames'
import { getRankingsETHW, getRankingsBSC, getRankingsTop, getRankingsTrending } from '@/api/rankings'
import { useSelector } from 'react-redux'
import RankContent from './components/RankContent/RankContent'
import Router from 'next/router'

import styles from './index.module.scss'

const topTabs = [
  {
    value: 1,
    label: 'Top',
  },
  {
    value: 2,
    label: 'Trending',
  },
]

const defaultParams = {
  limit: 5,
  offset: 0,
  range: '1d',
}

const HomeRanking = () => {
  const { visitChainId } = useSelector(state => state.chain)
  const [topTab, setTopTab] = useState(1)
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])

  const handleClickTopTab = (value) => {
    if (visitChainId !== 1 && value === 2) {
      return
    }
    setTopTab(value)
  }

  const goRanking = () => {
    Router.push('/rankings')
  }

  //获取数据
  useEffect(async() => {
    const fnArr = {
      1: topTab === 1 ? getRankingsTop : getRankingsTrending,
      10001: getRankingsETHW,
      56: getRankingsBSC,
    }

    setLoading(true)
    const res = await fnArr[visitChainId](defaultParams)
    setLoading(false)
    const rusult = res?.data?.result || []

    const newData = rusult.map((it, index) => {
      return {
        ...it,
        initialIndex: index,
        volume: Number(it.volume),
        floor_price: Number(it.floor_price),
      }
    })
    setDataSource(newData)
  }, [topTab, visitChainId])

  return (
    <div className={styles.rankings_container} id="key">
      <div className={styles.tabBox}>

        <div className={styles.tab_container}>
          {topTabs.map((item, index) => {
            return (
              <button
                key={item.label}
                className={classnames(item.value === topTab ? styles.checked : '')}
                onClick={() => handleClickTopTab(item.value)}
              >
                {item.label}
              </button>
            )
          })}
        </div>
        <div className={styles.viewAll} onClick={goRanking}>
          View All
        </div>
      </div>

      <RankContent
        chain={visitChainId}
        dataSource={dataSource}
        loading={loading}
        sortInfo={{}}
      />
    </div>
  )
}

export default HomeRanking