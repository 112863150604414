import React from 'react'
// Import Swiper React components
import styles from './community.module.scss'
import { imgOnError } from '@/utils/utils'
import { CommunityData } from '@utils/constant'

export default function Community() {

  const goDetail = (item) => {
    const { twitter } = item
    if (twitter) {
      window.open(twitter)
    }
  }
  return (
    <div className={styles.communityCon}>
      <div className={styles.title}>
        Community
      </div>
      <div className={styles.comBox}>
        {CommunityData.map((item) => {
          return (
            <div className={styles.comItem} key={item.bgSrc} onClick={() => goDetail(item)}>
              <div className={styles.comHeader}>
                <img src={item?.logo} width={284} height={124} alt="img" onError={imgOnError} />
              </div>
              <div className={styles.comFooter}>
                <div className={styles.comItemTitle}>
                  {item?.name}
                </div>
                <div className={styles.comItemDesc}>
                  {item?.desc}
                </div>
              </div>
              <div className={styles.maskBox}>
                <div className={styles.avatar_con}>
                  <div className={styles.avatar}>
                    <img src={item?.logo} width={72} height={72} alt="img" onError={imgOnError} />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
