import React, { useState, useEffect, useRef } from 'react'
import { getTrendingNftList } from '@/api/index'
import { errorCaptured } from '@contract/utils'
import { getChainName } from '@/utils/utils'
import ComImage from '@components/ComImage'
import { useRouter } from 'next/router'
import { getChainCurrencyName } from '@utils/networks.js'
import { NET_WORKS_IDS } from '@/utils/networks'
import { Swiper, SwiperSlide } from 'swiper/react'
import { HOME_CERTIFIELD } from '@utils/img'
import { usePhone } from '@utils/utils'
import { Navigation } from 'swiper'
import { REC_LEFT, REC_RIGHT } from '@utils/img'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import styles from './recommend.module.scss'
const Recommend = () => {

  console.log('recommend---')

  const [list, setList] = useState([])
  const router = useRouter()
  const isPhone = usePhone()

  const swiperRef = useRef(null)

  const chainId = NET_WORKS_IDS[router.query?.chainName]
  const getList = async() => {
    const [err, res] = await errorCaptured(getTrendingNftList, { page: 1, page_size: 4 })
    if (err) {
      console.log(err, 'trendingNftList error')
    }
    if (res) {
      const { result } = res?.data || {}
      setList(result || [])
    }
  }

  const goDetail = (item) => {
    const { contract_address } = item
    if (contract_address) {
      router.push(`/collection/eth/${contract_address}`)
    }
  }

  const navigation = {
    prevEl: `${styles.swiper_button_prev}`,
    nextEl: `${styles.swiper_button_next}`,
  }

  const handlePrev = (index) => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev()
    }
  }

  const handleNext = (index) => {
    if (swiperRef.current) {
      swiperRef.current.slideNext()
    }
  }

  useEffect(() => {
    getList()
  }, [])

  if (isPhone) {
    return (
      <div className={styles.recommendCon}>

        <div className={styles.title}>
          Recommend
        </div>
        <div className={styles.contentBox}>

          <Swiper
            pagination={{
              clickable: true,
            }}
            navigation={navigation}
            onSwiper={(swiper) => {
              swiperRef.current = swiper
            }}
            modules={[Navigation]}
            className={styles.mySwiper}
          >
            {list.map((item) => {
              return (
                <SwiperSlide key={item.id}>
                  <div className={styles.recItem} onClick={() => goDetail(item)}>
                    <div className={styles.recImgCon}>
                      <ComImage src={item.banner_uri} width={420} height={210} alt="img" className={styles.recImg} />
                      <div className={styles.recImgFooter}>
                        <div className={styles.recItemTitlte}>
                          {item?.name}
                        </div>
                        <div className={styles.recItemIcon}>
                          <img src={HOME_CERTIFIELD} />
                        </div>
                      </div>
                    </div>
                    <div className={styles.recTextCon}>
                      <div className={styles.recFooterBox}>
                        <div className={styles.recText}>
                          Floor Price
                        </div>
                        <div className={styles.recPrice}>
                          {Number(item?.floor_price).toFixed(4)}{getChainCurrencyName(chainId)}
                        </div>
                      </div>
                      <div className={styles.recFooterBox}>
                        <div className={styles.recText} style={{ textAlign: 'right' }}>
                          Volume
                        </div>
                        <div className={styles.recPrice}>
                          {Number(item?.volume).toFixed(4)}{getChainCurrencyName(chainId)}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <div className={styles.swiper_button_prev} onClick={handlePrev} >
            <img src={REC_LEFT} />
          </div>
          <div className={styles.swiper_button_next} onClick={handleNext}>
            <img src={REC_RIGHT} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.recommendCon}>
      <div className={styles.title}>
        Recommend
      </div>
      <div className={styles.contentBox}>
        {list.map( (item, index) => {
          return (
            <div className={styles.recItem} key={index} onClick={() => goDetail(item)}>
              <div className={styles.recImgCon}>
                <ComImage src={item.banner_uri} width={420} height={210} alt="img" className={styles.recImg} />
                <div className={styles.recImgFooter}>
                  <div className={styles.recItemTitlte}>
                    {item?.name}
                  </div>
                  <div className={styles.recItemIcon}>
                    <img src={HOME_CERTIFIELD} />
                  </div>
                </div>
              </div>
              <div className={styles.recTextCon}>
                <div className={styles.recFooterBox}>
                  <div className={styles.recText}>
                    Floor Price
                  </div>
                  <div className={styles.recPrice}>
                    {Number(item?.floor_price).toFixed(4)}{getChainCurrencyName(chainId)}
                  </div>
                </div>
                <div className={styles.recFooterBox}>
                  <div className={styles.recText} style={{ textAlign: 'right' }}>
                    Volume
                  </div>
                  <div className={styles.recPrice}>
                    {Number(item?.volume).toFixed(4)}{getChainCurrencyName(chainId)}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Recommend
