import { useEffect } from 'react'
import { exp } from '@utils/exp'

const Exp = ({ page }) => {
  useEffect(() => {
    exp({ page })
  }, [])

  return null
}

export default Exp