import React, { useState, useEffect } from 'react'
import { getRaffle } from '@/api/index'
import { formatTimestamp, formatTimestamp2 } from '@utils/time'
import Router from 'next/router'
import ComImage from '@components/ComImage'

import styles from './homeLucky.module.scss'
// import { useAccount } from 'wagmi'

const HomeLucky = () => {

  const [list, setList] = useState([])
  // const { isConnected } = useAccount()

  const getList = async () => {
    const res = await getRaffle({ limit: 5 })
    const { data } = res || {}
    const { result } = data || {}
    result && result?.length && setList(result)
  }

  const goProjectDetail = (item) => {
    const { raffle_id } = item || {}
    Router.push(`/raffles/detail/${raffle_id}`)
  }

  const getRaffleBtn = (item ) => {
    const { status, schedule_start_time } = item
    // 用户状态(0:未加入/未开始注册报名/未登录,1:已加入）

    if (status === 1) return 'Joined'

    const now = new Date().getTime()

    // 时间已经结束的不会在列表中
    if (schedule_start_time < now) {
      // 已经开始了
      return 'Join in'
    }
    // 没有开始现实 start in XX days
    return formatTimestamp2(schedule_start_time)
  }

  const goRaffle = () => {
    Router.push('/raffles')
  }

  useEffect(() => {
    getList()
  }, [])

  if (list.length === 0) return ''

  return (
    <div className={styles.homeLuckyCon}>
      <div className={styles.titleBox}>
        <div className={styles.title}>
          Lucky
        </div>
        <div className={styles.viewAll} onClick={goRaffle}>
          View All
        </div>
      </div>
      <div className={styles.contentBox}>
        {list.map( (item, index) => {
          return (
            <div className={styles.recItem} key={index} onClick={() => goProjectDetail(item)}>
              <div className={styles.recImgCon}>
                <ComImage src={item.image} width={176} height={176} alt="img" className={styles.recImg} />
              </div>
              <div className={styles.recTextCon} style={{ marginBottom: '8px' }}>
                <div className={styles.recTextTitle}>
                  {item?.name}
                </div>
              </div>
              <div className={styles.recTextCon}>
                <div className={styles.recFooterBox}>
                  <div className={styles.recText}>
                    Winner
                  </div>
                  <div className={styles.recPrice}>
                    {item?.winners}
                  </div>
                </div>
                <div className={styles.recFooterBox}>
                  <div className={styles.recText}>
                    Close in
                  </div>
                  <div className={styles.recPrice}>
                    {formatTimestamp(item?.schedule_end_time)}
                  </div>
                </div>
              </div>
              <div className={styles.enterBox}>
                {getRaffleBtn(item)}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default HomeLucky
