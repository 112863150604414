import React from 'react'
import ComImage from '@components/ComImage'
import { HOME_BANNER_ARROW, HOME_CERTIFIELD } from '@utils/img'
import classNames from 'classnames/bind'

import styles from './thumbItem.module.scss'

let cx = classNames.bind(styles)

const ThumbItem = ({ bgSrc, virtualIndex, activeIndex, title }) => {
  return (
    <div
      className={cx({ thumbItemCon: true, hasActive: activeIndex === virtualIndex })}
    >
      <ComImage src={bgSrc} width={1792} height={560} alt="banner" layout="fill" objectFit="cover" className={styles.bgImg}/>
      <div className={styles.descCon}>
        <div className={styles.title}>
          {title} <img src={HOME_CERTIFIELD} className={styles.certIcon}/>
        </div>
        <div className={cx({ rightArrow: true, activeColor: activeIndex === virtualIndex })}>
          <img src={HOME_BANNER_ARROW} />
        </div>
      </div>
    </div>
  )
}

export default ThumbItem